<template>
    <div>
        <template v-if="isWorking">
            <select v-if="data.currentTripAssign" class="form-control" :class="{'edited': data.currentTripAssign.edited === true}" name="vehicleId" v-model="data.currentTripAssign.vehicle" @change="setVehicle()" >
                <option :value="null"></option>
                <option v-for="vehicle in transportVehicles" :value="vehicle.id" :key="vehicle.id" 
                :disabled="vehicle.capacity && (getFilled(vehicle.id) >= vehicle.capacity && vehicle.id!=data.vehicle_id)">
                    (<template>{{getFilled(vehicle.id)}}</template>/{{vehicle.capacity}})
                        {{vehicle.name}}

                </option>
            </select>
        </template>

            <!-- <div v-for="vehicle in transportVehicles" :key="vehicle.id" class='d-inline'>
                <button
                    class="btn btn-sm vehicle-choice"
                    :class="{
                        'btn-outline-primary': vehicle.id!=data.vehicle_id,
                        'btn-primary': vehicle.id==data.vehicle_id && (!vehicle.capacity || getFilled(vehicle.id) < vehicle.capacity),
                        'btn-success': vehicle.id==data.vehicle_id && (vehicle.capacity && getFilled(vehicle.id) >= vehicle.capacity),}"
                    @click="setVehicle(vehicle.id)"
                    :disabled="vehicle.capacity && (getFilled(vehicle.id) >= vehicle.capacity && vehicle.id!=data.vehicle_id)"
                    >

                    (<template>{{getFilled(vehicle.id)}}</template>/{{vehicle.capacity}})
                    {{vehicle.name}}

                </button>
            </div> -->



    </div>
</template>

<script>
import UtilService from '@/service/UtilService.js';
import {mapState} from 'vuex';

import Vue from 'vue';

Vue.prototype.$eventHub = new Vue(); 

export default {
    name: 'grid-date-time-emp-assignment-template',

    data: function() {
        return {
            data: {},
            oldValue: null,
            ide:3,

        }
    },

    computed: {
        ...mapState([
            'transportVehicles',
        ]),

        filled() {
            return this.$parent.$parent.vehiclesCapacity;
        },

        isNonWorkingDay() {
            let dayStatus = this.nonWorkingDays.some(d => d.date == this.selected_date);
            return dayStatus;
        },

        isWorking() {
            if (this.data?.timeline?.[0] === undefined) return true;
            if (this.data?.timeline?.[0]?.working) return true;
            return false;
        }



    },

    props: {
        
        timelineTripIndex: {
            type: Number,
            default: null,
        },
        nonWorkingDays: Array,
        selected_date: String,

    },


    methods: {
        formatDatePattern: UtilService.formatDatePattern,
        absenceTypeFormat: UtilService.absenceTypeFormat,

        clearSelected() {
            this.data.currentTripAssign.vehicle = null;
        },

        setVehicle() {
            console.log(this.data.currentTripAssign);
            this.$set(this.data.currentTripAssign, "edited", true);
            this.$parent.$parent.setVehicle(this.data.id, this.data.currentTripAssign.vehicle);

        },

        getFilled(vehicleId){
            return this.$parent.$parent.vehiclesCapacity[vehicleId]
        }

    },


}
</script>

<style scoped>
    .row-choice {
        display: inline;
    }

    .row-choice .vehicle-choice {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .form-control.edited {
        background: #ff0;
    }
</style>