<template>
    <div class="modal fade" :id="modalId" tabindex="-1" data-bs-backdrop="static" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">

                    <div class="row mb-3">
                        <div class="col-6">
                            <div class="form-floating">
                                <input type="time" class="form-control" v-model="depatureTime" id="depatureTime" placeholder="Vreme polaska" required>
                                <label for="depatureTime">Vreme polaska</label>
                                <div class="invalid-feedback">
                                    Unesite vreme polaska
                                </div>
                            </div>   

                        </div>
                        <div class="col-6">

                            <div class="form-floating" v-if="returnTime !== null">
                                <input type="time" class="form-control" id="returnTime" @blur="changedReturnTime" v-model="returnTime" placeholder="Vreme povratka" required>
                                <label for="returnTime">Vreme povratka</label>
                                <div class="invalid-feedback">
                                    Unesite vreme povratka
                                </div>
                            </div>
                            <button v-else @click="returnTime = '17:00'" class="btn btn-light">Dodaj povratak</button>
                            
                        </div>

                    </div>
                    


                    <ul class="nav nav-tabs mb-3" id="vehicles" role="tablist">
                        <li v-for="(data, selected_vehicle_id, index) in selectedVehicles" :key="index" class="nav-item" :set="vehicle = getVehicle(selected_vehicle_id)">
                            <button class="nav-link" :class="{'active': selectedTab==index}" type="button" @click="changeTab(index)">{{vehicle.name}}</button>
                        </li>
                    </ul>

                    <div v-if="selectedVehicle">

                        <template v-if="selectedVehicle.all_passengers.length > 0">

                            <template v-if="selectedVehicle.driver">
                                <h6>Vozač:</h6>
                                <div class="list-group mb-3">

                                    <button type="button" class="list-group-item list-group-item-action">
                                        <span><font-awesome-icon icon="car-side" /></span>
                                        {{selectedVehicle.driver.user.first_name}} {{selectedVehicle.driver.user.last_name}}
                                    </button>
                                </div>
                            </template>
                            <template v-else>
                                <h5 class="mt-3">Izaberite vozača za {{selectedVehicle.vehicle.name}}:</h5>
                            </template>


                            <h6>Putnici:</h6>
                            <draggable
                                class="list-group"
                                tag="ul"
                                v-model="selectedVehicle.passenger"
                                v-bind="dragOptions"
                                :list="selectedVehicle.passengers"
                                handle=".handle"
                                @start="drag = true"
                                @end="drag = false"
                            >
                                <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                                <li
                                    class="passenger-item list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                                    v-for="(element, index) in selectedVehicle.passengers"
                                    :key="element.id"
                                >
                                    <span class="handle">
                                        <font-awesome-icon icon="align-justify" />
                                    </span>
                                    <span class="w-100 passenger-name" @click="selectDriver(element)">
                                        {{index + 1}}.
                                        {{element.user.first_name}} {{element.user.last_name}}
                                    </span>
                                    <span @click="removePassenger(element.user.id)" class="cross-icon">
                                        <font-awesome-icon icon="times" />
                                    </span>
                                </li>
                                </transition-group>
                            </draggable>
                        </template>
                        <template v-else>
                            <h3>Nema putnika</h3>
                        </template>


                    </div>   
                </div>

                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Odustani</button>

                    <div>
                        <button type="button" class="btn btn-primary" :disabled="selectedTab <= 0" @click="changeTab(selectedTab - 1)"><font-awesome-icon icon="angle-left"/></button>
                        <button type="button" class="btn btn-primary" :disabled="selectedTab >= vehiclesTotal - 1" @click="changeTab(selectedTab + 1)"><font-awesome-icon icon="angle-right"/></button>
                        <button type="button" class="btn btn-primary" :disabled="!selectedDrivers" @click="saveAllVehicleTrips">Dodeli sve</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapState} from 'vuex';
    import draggable from "vuedraggable";
    import TransportService from '@/service/TransportService.js'
    import UtilService from '@/service/UtilService.js';
    import { Modal } from 'bootstrap';

    export default {
        props: {
            modalId: String,
            title: String,
            employees: Array,
            date: String,
        },

        components: {
            draggable,
        },

        data() {
            return {
                assignTripModal: null,

                depatureTime: "07:00",
                returnTime: null,
                selectedVehicles: {},
                selectedTab: 0,

                drag: false,

                vehicleTripMap: {},
                old_vehicles: {},
            }
        },

        computed: {
            ...mapState([
                'transportVehicles',
                'userTrips',
            ]),

            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },

            selectedVehicle() {
                return Object.values(this.selectedVehicles)[this.selectedTab];
            },

            vehiclesTotal() {
                return Object.values(this.selectedVehicles).length;
            },

            selectedDrivers() {
                return !Object.values(this.selectedVehicles).some(vehicle => vehicle.driver == undefined && vehicle.all_passengers.length > 0);
            },

            startDepatureTime() {
                return this.date + "T" + this.depatureTime;
            },

            startReturnTime() {
                return this.date + "T" + this.returnTime;
            },

            
        },

        methods: {
            getVehicle(id) {
                return this.transportVehicles.find(x => x.id == id);
            },

            getTripId(id) {
                if (!id) return;

                let trip = this.userTrips.find(x => x.id == id);
                this.vehicleTripMap[trip.vehicle] = trip;
            },

            injectTripData() {
                Object.values(this.selectedVehicles).forEach(vehicleTrip => {
                    if (this.vehicleTripMap[vehicleTrip.vehicle.id]) {
                        let old = this.vehicleTripMap[vehicleTrip.vehicle.id];

                        vehicleTrip.id = old.id;
                        vehicleTrip.driver = vehicleTrip.all_passengers.find(x => x.id == old.driver);
                        vehicleTrip.name = old.name;
                        vehicleTrip.distance = old.distance;
                        vehicleTrip.start_time = old.start_time;
                        vehicleTrip.end_time = old.end_time;
                        // vehicleTrip.passengers_data = old.passengers_data;
                        vehicleTrip.end_location = old.end_location;

                        vehicleTrip.passengers = vehicleTrip.passengers.filter(x => x.id != vehicleTrip?.driver?.id)
                    }
                });

                let self = this;
                Object.values(this.vehicleTripMap).forEach(trip => {
                    if (!Object.values(self.selectedVehicles).some(x => x.vehicle.id == trip.vehicle)) {

                        self.$set(this.selectedVehicles, trip.vehicle, {
                            'id': trip.id,
                            'driver': null,
                            'vehicle': trip.vehicle_obj,
                            'order': 0,
                            'all_passengers': [],
                            'passengers': [],
                            })

                    }
                });
            },

            loadSelectedVehicles() {
                this.selectedVehicles = {};
                this.vehicleTripMap = {};
                const self = this;
                this.employees.forEach((employee) => {
                    if (employee.currentTripAssign.id) this.getTripId(employee.currentTripAssign.id);
                    if (!employee.currentTripAssign?.vehicle) return;

                    let selected_vehicle_id = employee.currentTripAssign?.vehicle;
                    let old_selected_vehicle_id = employee.currentTripAssign?.old_vehicle;

                    if (old_selected_vehicle_id) {
                        this.old_vehicles[old_selected_vehicle_id] = true;
                    }

                    if (!self.selectedVehicles[selected_vehicle_id]) {
                        self.$set(this.selectedVehicles, selected_vehicle_id, {
                            'driver': null,
                            'vehicle': self.getVehicle(selected_vehicle_id),
                            'order': 0,
                            'all_passengers': [],
                            })

                        self.$set(this.selectedVehicles[selected_vehicle_id], 'passengers', []);

                    }
                    self.selectedVehicles[selected_vehicle_id].all_passengers.push(employee);
                    self.selectedVehicles[selected_vehicle_id].passengers.push(employee);

                    if (employee.currentTripAssign.driver) {
                        self.selectedVehicles[selected_vehicle_id].driver = employee;
                    }
                    
                });

                self.injectTripData();
            },

            getPassengersOnly(vehicle) {
                if (!vehicle.driver) return vehicle.all_passengers;

                return vehicle.all_passengers.filter(x => x.id != vehicle.driver.id);
            },

            changeTab(index) {
                this.selectedTab = index;
            },


            selectDriver(driver) {
                this.selectedVehicle.driver = driver
                this.selectedVehicle.passengers = this.getPassengersOnly(this.selectedVehicle)
            },

            changedReturnTime() {
                if (this.returnTime == "") this.returnTime = null;
            },

            load() {
                this.loadSelectedVehicles();
                this.assignTripModal = new Modal(document.getElementById('assignTripModal'));

            },


            async saveVehicleTrip(trip, assign_time=this.startDepatureTime) {
                let hasError = false;
                let data = {
                    name:"Prevoz putnika",
                    driver:trip.driver.id,
                    vehicle: trip.vehicle.id,
                    distance: 1,
                    start_time: UtilService.convertMoment(assign_time).format("YYYY-MM-DDTHH:mm:SS"),
                    end_time: UtilService.convertMoment(assign_time).add(1, 'hour').format("YYYY-MM-DDTHH:mm:SS"),
                    passengers_data: trip.all_passengers.map(x => {return {user_id: x.id} }),
                    end_location: trip.driver.timeline?.[0]?.work_order_obj.name || "/",
                }
                await TransportService.createVehicleTrip(data).then((response) => {

                    let time =  UtilService.convertMoment(assign_time).format("DD.MM.YYYY HH:mm")
                    this.toast("Uspešno upućeno vozilo " + trip.vehicle.name + " za datum " + time + " u " + data.end_location);
                    console.log(response);
                }).catch((error) => {
                    console.log(error);
                    hasError = error;
                })

                return hasError;
            },

            async updateVehicleTrip(trip) {
                let hasError = false;

                let data = {
                    id: trip.id,
                    name:"Prevoz putnika",
                    driver: trip.driver.id,
                    vehicle: trip.vehicle.id,
                    distance: trip.distance,
                    start_time: trip.start_time,
                    end_time: trip.end_time,
                    passengers_data: trip.all_passengers.map(x => {return {user_id: x.id} }),
                    end_location: trip.end_location,
                };

                await TransportService.updateVehicleTrip(data).then((response) => {

                    let time =  UtilService.convertMoment(trip.start_time).format("DD.MM.YYYY HH:mm")
                    this.toast("Uspešno izmenjeno vozilo " + trip.vehicle.name + " za datum " + time + " u " + data.end_location);
                    console.log(response);
                }).catch((error) => {
                    console.log(error);
                    hasError = error;
                })

                return hasError;
            },

            async deleteVehicleTrip(trip) {
                let hasError = false;

                let data = {id: trip.id};
            
                await TransportService.deleteVehicleTrip(data).then((response) => {

                    let time =  UtilService.convertMoment(trip.start_time).format("DD.MM.YYYY HH:mm")
                    this.toast("Uspešno otkazano vozilo " + trip.vehicle.name + " za datum " + time + " u " + data.end_location);
                    console.log(response);
                }).catch((error) => {
                    console.log(error);
                    hasError = error;
                })

                return hasError;
            },


            async saveAllVehicleTrips() {
                let error = false;
                await Object.values(this.selectedVehicles).forEach(async vehicle => {
                    if (vehicle.id) {
                        if (vehicle.driver)
                            error = await this.updateVehicleTrip(vehicle) || error;
                        else 
                            error = await this.deleteVehicleTrip(vehicle) || error;

                    } else {
                        error = await this.saveVehicleTrip(vehicle) || error;
                        if (this.returnTime) error = await this.saveVehicleTrip(vehicle, this.startReturnTime) || error;
                    }
                    
                });

                console.log(error);
                if (!error) {
                    this.$emit('submited');
                }


            },

            removePassenger(userId) {
                this.selectedVehicle.passengers = this.selectedVehicle.passengers.filter(x=> x.user.id != userId)
                this.selectedVehicle.all_passengers = this.selectedVehicle.all_passengers.filter(x=> x.user.id != userId)
            },
            
            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 20000
               });
            },

        },

        mounted() {
        }
    }
</script>

<style scoped>
    .handle {
        padding: 10px;
        margin-left: -10px;
        cursor: move;
    }

    .passenger-name {
        cursor: pointer;
    }

    .cross-icon {
        padding: 10px;
        cursor: pointer;
    }



    .passenger-item {
        padding-top: 0;
        padding-bottom: 0;
    }
    

</style>